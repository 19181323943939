import React, {useEffect, useState} from 'react';
import { Form, FloatingLabel, Button } from 'react-bootstrap';

const GameTotalsFilter = (props) => {
    const [sport, setSport] = useState("");
    const [formType, setFormType] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const [initCrit, setInitCrit] = useState(null);
    const [critIdx, setCritIdx] = useState(null);
    const [errs, setErrs] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
    const sportLabels = {
        mlb: "runs",
        nfl: "points",
        nba: "points"
    };
    const formFields = {
        min:
            <FloatingLabel label={`Minimum total ${sportLabels[sport]}`} className='sysField'>
                <Form.Control id="min" type="number" value={min} onChange={(e)=>convertToNum(e.target.value, setMin)}/>
            </FloatingLabel>,
        max: 
            <FloatingLabel label={`Maximum total ${sportLabels[sport]}`} className='sysField'>
                <Form.Control id="max" type="number" value={max} onChange={(e)=>convertToNum(e.target.value, setMax)}/>
            </FloatingLabel>,
        type: 
            <FloatingLabel label='Choose filter' id="total-div" className='sysField'>
                <Form.Control id="totals-type" as="select" value={formType} onChange={(e)=>setFormType(e.target.value)}>
                    <option value=""></option>
                    <option value="between">{`Total ${sportLabels[sport]} are between`}</option>
                    <option value="greater">{`Total ${sportLabels[sport]} are greater than`}</option>
                    <option value="less">{`Total ${sportLabels[sport]} are less than`}</option>
                </Form.Control>
            </FloatingLabel>
    };

    const forms = { 
        between: 
            <>
                {formFields.min}
                {formFields.max}
            </>,
        greater: formFields.min,
        less: formFields.max    
    };

    // run when init crit changes
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            const critCopy = {...props.initCrit.crit};
            setFormType(critCopy.rangeType);
            setInitCrit(critCopy);
            setCritIdx(props.initCrit.index);
            switch(critCopy.rangeType){
                case "between":
                    setMin(critCopy.min);
                    setMax(critCopy.max);
                    break;
                case "greater":
                    setMin(critCopy.min);
                    break;
                case "less":
                    setMax(critCopy.max);
                    break;
                default: 
                    break;
            };
        };
    },[props.initCrit])

    // function to check for errors
    const checkErrs = () => {
        let tmpErrs = [];
        let tmpErrMsg = null;
        switch(formType){
            // Between - min and max need to be numbers & min < max
            case "between":
                if(isNaN(min) || min === "" || min < 0){
                    tmpErrs.push("min");
                };
                if(isNaN(max) || max === "" || max < 0){
                    tmpErrs.push("max");
                };
                if(!isNaN(min) && min !== "" && max > 0 && !isNaN(max) && max !== "" && min > 0){
                    if(min >= max){
                        tmpErrs.push("min");
                        tmpErrs.push("max");
                        tmpErrMsg = `Please ensure the minimum total ${sportLabels[sport]} are less than the maximum`;
                    };
                };
                break;
            case "greater":
                if(isNaN(min) || min === "" || min < 0){
                    tmpErrs.push("min");
                };
                break;
            case "less":
                if(isNaN(max) || max === "" || max < 0){
                    tmpErrs.push("max");
                };
                break;
            default:
                tmpErrs.push("totals-type");
                break; 
        };

        return({errs: tmpErrs, msg: tmpErrMsg});
    };

    // Function to get label for criteria
    const getLabel = () => {
        let label = "";
        switch(formType){
            case "between":
                label = `Total ${sportLabels[sport]} line is between ${min} and ${max}`;
                break;
            case "greater":
                label = `Total ${sportLabels[sport]} line is ${min} or greater`;
                break;
            case "less":
                label = `Total ${sportLabels[sport]} line is ${max} or less`;
                break;
        };
        return label; 
    };

    // Function to get criteria for submission
    const getCrit = () => {
        let crit = {
            type: "line.game-totals",
            rangeType: formType,
        };
        const label = getLabel();
        crit.label = label;
        switch(formType){
            case "between":
                crit.min = min;
                crit.max = max;
                break;
            case "greater":
                crit.min = min;
                break;
            case "less":
                crit.max = max;
                break;
        };
        return crit; 
    };

    // function to check if init criteria has changed
    const checkCritChange = (crit) => {
        let modified = false;
        // no initial criteria passed - set modified to true
        if(critIdx != null){
            // check if range type changed
            if(crit.rangeType !== initCrit.rangeType){
                modified = true;
            // check values for changes 
            }else{
                switch(crit.rangeType){
                    case "between":
                        if(crit.min !== initCrit.min || crit.max !== initCrit.max){
                            modified = true;
                        };
                        break;
                    case "greater":
                        if(crit.min !== initCrit.min){
                            modified = true;
                        };
                        break;
                    case "less":
                        if(crit.max !== initCrit.max){
                            modified = true;
                        };
                        break;
                };
            };
        }else{
            modified = true;
        };
        return modified;
    };

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // check for errors
        const errCheck = checkErrs(); 
        setErrMsg(errCheck.msg);
        setErrs(errCheck.errs);
        if(errCheck.errs.length === 0){
            // check for crit change - returns true if initCrit was modified or not passed
            const crit = getCrit(); 
            const modified = checkCritChange(crit);
            if(modified){
                props.sysContext.changeCrit(crit, critIdx);
                props.sysContext.setCritDisp(null);
            };
            props.sysContext.setStep(2);
        };
    };

    // function to convert sting to number
    const convertToNum = (val, cb) => {
        let parsed = "";
        // if value is a number - parse the int or float
        if (val !== "" && !isNaN(val)) {
            parsed = val.includes(".") ? parseFloat(val) : parseInt(val);
        };
        cb(parsed);
    };

    // Run when system context changes - set formType and bet label
    useEffect(()=>{
        const sysSport = props.sysContext.sport;
        setSport(sysSport);
    },[props.sysContext]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);

    return (
        <>
            <div id='sysFormTopRow'>
                <h2>Game Totals Filter</h2>
            </div>
            <Form>
                {formFields.type}
                {formType !== "" && forms[formType]}
                {errMsg && (<p className='errMsg'>{errMsg}</p>)}
            </Form>
            <div id='submit-btns'>
                <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
            </div>
        </>
    )

}

export default GameTotalsFilter;