// team id -> team name conversion
export const nbaTeams = {
    "ATL": "Hawks",
    "BKN": "Nets",
    "BOS": "Celtics",
    "CHA": "Hornets",
    "CHI": "Bulls",
    "CLE": "Cavaliers",
    "DAL": "Mavericks",
    "DEN": "Nuggets",
    "DET": "Pistons",
    "GS": "Warriors",
    "HOU": "Rockets",
    "IND": "Pacers",
    "LAC": "Clippers",
    "LAL": "Lakers",
    "MEM": "Grizzlies",
    "MIA": "Heat",
    "MIL": "Bucks",
    "MIN": "Timberwolves",
    "NO": "Pelicans",
    "NY": "Knicks",
    "OKC": "Thunder",
    "ORL": "Magic",
    "PHI": "76ers",
    "PHO": "Suns",
    "POR": "Trail Blazers",
    "SA": "Spurs",
    "SAC": "Kings",
    "TOR": "Raptors",
    "UTA": "Jazz",
    "WAS": "Wizards"
}

export const nbaConferences = {
    "East": ["CLE", "BOS", "NY", "CHA", "BKN",
             "IND", "DET", "ORL", "ATL", "MIA", 
             "CHI", "WAS", "TOR", "MIL", "PHI"],
    "West": ["PHO", "OKC", "GS", "MIN", "DEN",
             "MEM", "HOU", "LAL", "LAC", "DAL",
             "SAC", "SA", "POR", "NO", "UTA"]
};

export const nbaStatVerbs = {
    "off": {
        "stats.Possessions": "are",
        "stats.FieldGoalsMade": "are",
        "stats.FieldGoalsAttempted": "are",
        "stats.TwoPointersMade": "are",
        "stats.TwoPointersAttempted": "are",
        "stats.ThreePointersMade": "are",
        "stats.ThreePointersAttempted": "are",
        "stats.FreeThrowsMade": "are",
        "stats.OffensiveRebounds": "are",
        "stats.Assists": "are",
        "stats.Turnovers": "are",
        "stats.Points": "are",
        "stats.TrueShootingAttempts": "are",
        "stats.DoubleDoubles": "are",
        "stats.TripleDoubles": "are",
        "calculated.FieldGoalPercentage": "is",
        "calculated.TwoPtFgPercentage": "is",
        "calculated.ThreePtFgPercentage": "is",
        "calculated.FtPercentage": "is",
        "calculated.EffectiveFgPercentage": "is",
        "calculated.TrueShootingPercentage": "is",
        "calculated.PtsPerPossession": "is",
        "calculated.FtRate": "is",
        "calculated.ThreePtAttemptRate": "is",
        "calculated.PercentagePtsFromTwo": "is",
        "calculated.PercentagePtsFromThree": "is",
        "calculated.PercentagePtsFromFt": "is",
        "calculated.OffensiveEfficiencyRating": "is",
        "calculated.AssistPercentage": "is"
    },
    "def": {
        "stats.DefensiveRebounds": "are",
        "stats.Steals": "are",
        "stats.BlockedShots": "are",
        "calculated.BlockToFoulRatio": "is",
        "calculated.TurnoverRatio": "is",
        "calculated.TurnoverPercentage": "is"
    },
    "gen": {
        "stats.Rebounds": "are",
        "stats.PersonalFouls": "are",
        "calculated.AssistToTurnoverRatio": "is"
    }
};

export const nbaStats = {
    "off" : {
        "stats.Possessions": "possessions",
        "stats.FieldGoalsMade": "fgs made",
        "stats.FieldGoalsAttempted": "fgs attempted",
        "stats.TwoPointersMade": "two pointers made",
        "stats.TwoPointersAttempted": "two pointers attempted",
        "stats.ThreePointersMade": "three pointers made",
        "stats.ThreePointersAttempted": "three pointers attempted",
        "stats.FreeThrowsMade": "free throws made",
        "stats.OffensiveRebounds": "offensive rebounds",
        "stats.Assists": "assists",
        "stats.Turnovers": "turnovers",
        "stats.Points": "points scored",
        "stats.TrueShootingAttempts": "true shooting attempts",
        "stats.DoubleDoubles": "double doubles",
        "stats.TripleDoubles": "triple doubles",
        "calculated.FieldGoalPercentage": "fg percentage",
        "calculated.TwoPtFgPercentage": "two point fg percentage",
        "calculated.ThreePtFgPercentage": "three point fg percentage",
        "calculated.FtPercentage": "free throw percentage",
        "calculated.EffectiveFgPercentage": "effective fg percentage",
        "calculated.TrueShootingPercentage": "true shooting percentage",
        "calculated.PtsPerPossession": "pts per possession",
        "calculated.FtRate": "free throw rate",
        "calculated.ThreePtAttemptRate": "three pt attempt rate",
        "calculated.PercentagePtsFromTwo": "percentage of pts from two",
        "calculated.PercentagePtsFromThree": "percentage of pts from three",
        "calculated.PercentagePtsFromFt": "percentage of pts from free throws",
        "calculated.OffensiveEfficiencyRating": "offensive efficiency rating",
        "calculated.AssistPercentage": "assist percentage",
        "calculated.AssistToTurnoverRatio": "assist to turnover ratio",
        "calculated.TurnoverRatio": "turnover ratio",
        "calculated.TurnoverPercentage": "turnover percentage"
    },
    "def": {
        "stats.DefensiveRebounds": "defensive rebounds",
        "stats.Steals": "steals",
        "stats.BlockedShots": "blocked shots",
        "calculated.BlockToFoulRatio": "block to foul ratio",
    },
    "gen": {
        "stats.Rebounds": "total rebounds",
        "stats.PersonalFouls": "personal fouls",
    }
};