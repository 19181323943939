import calcProfitAm from "./calcProfit";
import apiService from "../apiService";

// function to format systems
export const formatSystem = (system) => {
    let units = 1;
    let w = 0, l = 0, p = 0, prof = 0, roiData = [];
    // loop over each season in matches to get w,l,p, profit, and roi data for each system
    let seasons = Object.keys(system.matches);
    for(const szn of seasons){
        if(system.matches[szn].length > 0){
            for(const game of system.matches[szn]){
                let betProf;
                if(game.bet.result === "W"){
                    w = w+1;
                    betProf = calcProfitAm(game.bet.odds, units);
                    prof = prof + betProf;
                }else if(game.bet.result === "L"){
                    l = l+1;
                    prof = prof - units;
                }else if(game.bet.result === "P"){
                    p = p+1;
                };
                roiData.push((prof/((w+l)*units))*100)
            };
        }
    }
    // calculate ROI
    let roi = (prof/((w+l)*units))*100
    return({...system, w: w, l:l, p:p, profit:prof.toFixed(2), roi:roi.toFixed(2), roiData:roiData});
};

// function to get next 100 public systems 
export const fetch_public_systems = async(sport, lastId=null) =>{
    try{
        let query = `?sport=${sport}`;
        if(lastId){
            query += `&lastId=${lastId}`;
        }
        const response = await apiService(`systems/public${query}`);
        // loop over systems and get w l p and profit breakdown
        const systems = response.map(system=>{
            const formatted = formatSystem(system);
            return formatted;
        });
        return systems;
    }catch(error){
        console.warn("Error fetching public systems");
        console.error(error);
        return([]);
    };
};

// function to follow system
export const follow_system = async(system_id, sport, clearSession, navigate) => {
    try{
        const response = await apiService(`systems/follow-system?system_id=${system_id}&sport=${sport}`);
        return response;
    }catch(error){
        if(error === 'Server Error' || error === 'Session Expired'){
            clearSession();
            navigate('/signin');
        };
    };
};

// function to get a users systems
export const fetch_user_systems = async(user_id, clearSession, navigate) => {
    try{
        const response = await apiService(`systems/get?user_id=${user_id}`);
        // loop over systems and get w l p and profit breakdown
        const privateSystems = response.private.map(system=>{
            const formatted = formatSystem(system);
            return formatted;
        });
        const followedSystems = {};
        // get public systems 
        if(Object.keys(response.followed).length > 0){
            for(const key in response.followed){
                if(key === "mlb"){
                    followedSystems[key] = response.followed[key].map(system=>{
                        const formatted = formatSystem(system);
                        return formatted;
                    });
                }else if(key === "nfl"){
                    followedSystems[key] = response.followed[key].map(system=>{
                        const formatted = formatSystem(system);
                        return formatted;
                    });
                };
            };
        };
        return {
            privateSystems,
            followedSystems
        };
    }catch(error){
        if(error === 'Server Error' || error === 'Session Expired'){
            clearSession();
            navigate('/signin');
        };
    };
};