import React, { useEffect, useState } from 'react';
import {Form} from "react-bootstrap";
import { nbaTeams, nbaStats } from '../../../utils/nbaUtils';

const NbaStatAvgs = (props) => {
    const game = props.game;
    const home_team = nbaTeams[game.home.team_id];
    const away_team = nbaTeams[game.away.team_id];

    const [offStats, setOffStats] = useState(null);
    const [defStats, setDefStats] = useState(null);
    const [genStats, setGenStats] = useState(null);
    const [activeTab, setActiveTab] = useState('off');
    const [renderedRows, setRenderedRows] = useState([]);

    const offLabels = {
        "stats.Possessions": "Possessions",
        "stats.FieldGoalsMade": "FG Made",
        "stats.FieldGoalsAttempted": "FG Attempted",
        "calculated.FieldGoalPercentage": "FG Percentage",
        "stats.TwoPointersMade": "2pt Made",
        "stats.TwoPointersAttempted": "2pt Attempts",
        "stats.ThreePointersMade": "3pt Made",
        "stats.ThreePointersAttempted": "3pt Attempts",
        "stats.FreeThrowsMade": "FT Made",
        "stats.OffensiveRebounds": "Off Rebounds",
        "stats.Assists": "Assists",
        "stats.Turnovers": "Turnovers",
        "stats.Points": "Pts Scored",
        "stats.TrueShootingAttempts": "True Shooting Attempts",
        "calculated.TrueShootingPercentage": "True Shooting Percentage",
        "stats.DoubleDoubles": "Double Doubles",
        "stats.TripleDoubles": "Triple Doubles",
        "calculated.TwoPtFgPercentage": "2pt FG Percentage",
        "calculated.ThreePtFgPercentage": "3pt FG Percentage",
        "calculated.FtPercentage": "FT Percentage",
        "calculated.EffectiveFgPercentage": "Effective FG Percentage",
        "calculated.PtsPerPossession": "Pts per Possession",
        "calculated.FtRate": "FT Rate",
        "calculated.ThreePtAttemptRate": "3pt Attempt Rate",
        "calculated.PercentagePtsFromTwo": "Percentage Pts from Two",
        "calculated.PercentagePtsFromThree": "Percentage Pts from Three",
        "calculated.PercentagePtsFromFt": "percentage of Pts from FT",
        "calculated.OffensiveEfficiencyRating": "Off Efficiency Rating",
        "calculated.AssistPercentage": "Assist Percentage",
        "calculated.AssistToTurnoverRatio": "Assist to Turnover Ratio",
        "calculated.TurnoverRatio": "Turnover Ratio",
        "calculated.TurnoverPercentage": "Turnover Percentage"
    };
    
    const defLabels = {
        "stats.DefensiveRebounds": "Def Rebounds",
        "stats.Steals": "Steals",
        "stats.BlockedShots": "Blocks",
        "calculated.BlockToFoulRatio": "Block to Foul Ratio"
    }

    const genLabels = {
        "stats.Rebounds": "Total Rebounds",
        "stats.PersonalFouls": "Personal Fouls",
    };

    const renderTableRows = (stats, labels) => {
        if (!stats || Object.keys(stats).length === 0) {
            return (
                <tr>
                    <td colSpan="3" style={{ textAlign: 'center' }}>Stats Unavailable</td>
                </tr>
            );
        }

        return Object.keys(stats).map((stat) => {
            const label = labels[stat] || "";
            return (
                <tr key={stat}>
                    <td>{label}</td>
                    <td>{stats[stat][0]}</td>
                    <td>{stats[stat][1]}</td>
                </tr>
            );
        });
    };

    // Run when props change - get the stats arrays
    useEffect(()=>{
        if('stats' in game.home && 'stats' in game.away){
            const offKeys = Object.keys(nbaStats.off);
            const defKeys = Object.keys(nbaStats.def);
            const genKeys = Object.keys(nbaStats.gen);

            let tmpOffStats = {}, tmpDefStats = {}, tmpGenStats = {};

            // get offensive stats
            offKeys.forEach(stat=>{
                // get stat split (stat/calc, and stat)
                const statSplit = stat.split(".");
                // add stat
                tmpOffStats[stat] = [game.home[statSplit[0]][statSplit[1]], game.away[statSplit[0]][statSplit[1]]]
            });
            // get offensive passing stats
            defKeys.forEach(stat=>{
                // get stat split (stat/calc, and stat)
                const statSplit = stat.split(".");
                // add stat
                tmpDefStats[stat] = [game.home[statSplit[0]][statSplit[1]], game.away[statSplit[0]][statSplit[1]]]
            });
            genKeys.forEach(stat=>{
                // get stat split (stat/calc, and stat)
                const statSplit = stat.split(".");
                // add stat
                tmpGenStats[stat] = [game.home[statSplit[0]][statSplit[1]], game.away[statSplit[0]][statSplit[1]]]
            });

            // set stats state variables
            setOffStats(tmpOffStats);
            setDefStats(tmpDefStats);
            setGenStats(tmpGenStats);
        };
    }, [props]);

    // Rerender table when active tab changes
    useEffect(() => {
        if (activeTab === 'off') {
            setRenderedRows(renderTableRows(offStats, offLabels));
        } else if (activeTab === 'def') {
            setRenderedRows(renderTableRows(defStats, defLabels));
        } else if (activeTab === 'gen') {
            setRenderedRows(renderTableRows(genStats, genLabels));
        }
    }, [activeTab, offStats, defStats, genStats]);
    
    return(
        <>
            <Form.Select id="bet-select" value={activeTab} onChange={e => setActiveTab(e.target.value)}>
                <option value="off">Offense</option>
                <option value="def">Defense</option>
                <option value="gen">General</option>
            </Form.Select>
            <table className='statsTable'>
                <thead>
                    <tr>
                        <th></th>
                        <th>{home_team}</th>
                        <th>{away_team}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderedRows}
                </tbody>
            </table>
        </>
    )
   

};

export default NbaStatAvgs;