import React, { useEffect, useState } from 'react'
import {useSelector} from 'react-redux';
import '../../../scss/pages/upcomingGames.scss'
import {useNavigate} from 'react-router-dom';
import GamePrev from './GamePrev';
import { NavDropdown } from 'react-bootstrap';
import AuthProtected from '../../HOCs/AuthProtected';
// images 
import {ReactComponent as BaseballIcon} from '../../../images/icons/sports/baseball.svg';
import {ReactComponent as FootballIcon} from '../../../images/icons/sports/football.svg';
import {ReactComponent as BasketballIcon} from '../../../images/icons/sports/basketball.svg';

const UpcomingGames = () => {
    const navigate = useNavigate();
    const matches = useSelector((state) => state.user.matches);
    const mlbUpcoming = useSelector((state) => state.mlb.upcoming);
    const nflUpcoming = useSelector(state => state.nfl.upcoming);
    const nbaUpcoming = useSelector(state => state.nba.upcoming);
    const [matchCounts, setMatchCounts] = useState({});
    const [sport, setSport] = useState("mlb");
    const [upcoming, setUpcoming] = useState(null);
    const [sportLabel, setSportLabel] = useState(<></>);

    // when sport selection changes update the private, public, and followed systems and the sport label
    useEffect(()=>{
        if(sport==="mlb"){
            setUpcoming(mlbUpcoming)
            setSportLabel(<span id='sportLabel'>
                <BaseballIcon className='labelIcon'/>
                <span>MLB</span>
            </span>)
        }else if(sport==="nfl"){
            setUpcoming(nflUpcoming)
            setSportLabel(<span id='sportLabel'>
                <FootballIcon className='labelIcon'/>
                <span>NFL</span>
            </span>)
        }else if(sport==="nba"){
            setUpcoming(nbaUpcoming)
            setSportLabel(<span id='sportLabel'>
                <BasketballIcon className='labelIcon'/>
                <span>NBA</span>
            </span>)
        }
    },[sport])

    // get match count for each game 
    const getMatchCount = () =>{
        let tmpCounts = {}
        for(const match of matches){
            if(match["game_id"] in tmpCounts){
                tmpCounts[match["game_id"]] += 1;
            }else{
                tmpCounts[match["game_id"]] = 1;
            };
        };
        setMatchCounts(tmpCounts);
    };

    const goToGame = (game) => {
        navigate(`/upcoming-game/${game._id}`, {state:{game:game, sport: sport}})
    };
    
    // run whenever matches or upcoming changes 
    useEffect(()=>{
        if(Array.isArray(matches) && Array.isArray(upcoming)){
            getMatchCount();
        };
    },[matches, upcoming])


    return (
        <div className='full-page'>
            <div className='page-section'>
                <div className='matches-page'>
                    <div className='top_row'>
                        <h1>Upcoming Games</h1>
                        <NavDropdown id="sportSelection" menuVariant='sport-sel' title={sportLabel}>
                            <NavDropdown.Item id='baseball' className='sportSel' onClick={()=>setSport("mlb")}>
                                <BaseballIcon className='sportsIcon'/>
                                <span>MLB</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item id='football' className='sportSel' onClick={()=>setSport("nfl")}>
                                <FootballIcon className='sportsIcon'/>
                                <span>NFL</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item id='football' className='sportSel' onClick={()=>setSport("nba")}>
                                <BasketballIcon className='sportsIcon'/>
                                <span>NBA</span>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='gamesList'>
                        {upcoming !== null && upcoming.length > 0 ?
                            (
                                upcoming.map((game) =>{
                                    let count = null;
                                    
                                    if(game._id in matchCounts){
                                        count = matchCounts[game._id]
                                    }
                                    return(                                                                
                                        <GamePrev key={game._id} game={game} game_id={game._id} sport={sport} onClick={()=>goToGame(game, sport)} match_count={count} />                 
                                    )
                                })
                            ) :
                            (<h6>No Upcoming Games</h6>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AuthProtected(UpcomingGames);