import React from 'react'
import { useSelector } from 'react-redux';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import SystemPrev from '../../Systems/SystemPrev';
import '../../../scss/pages/game.scss';
import {formatDatetimeString} from '../../../utils/general';
import MlbStatAvgs from './MlbStatAvgs';
import NflStatAvgs from './NflStatAvgs';
import NbaStatAvgs from './NbaStatAvgs';
import { nflTeams } from '../../../utils/nflUtils';
import { nbaTeams } from '../../../utils/nbaUtils';
import MlbBettingData from './MlbBettingData';
import SportBettingData from './SportBettingData';
import AuthProtected from '../../HOCs/AuthProtected';

const UpcomingGame = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const mlbFollowedSystems =  useSelector(state => state.user.followedSystems.mlb);
    const nflFollowedSystems =  useSelector(state => state.user.followedSystems.nfl);
    const nbaFollowedSystems =  useSelector(state => state.user.followedSystems.nba);

    let date = '';
    let time = '';

    // extract game id and game object
    let game_id = params.id;
    const game = location.state.game;
    const sport = location.state.sport;
    let home_team, away_team, sportLabel = '';
    // get all system matches for this game
    const matches = useSelector((state) => state.user.matches);
    const privateSystems = useSelector((state) => state.user.privateSystems);
    let followedSystems = null;
    if(sport === "mlb"){
        followedSystems = mlbFollowedSystems;
        date = game.date;
        home_team = game.home.team;
        away_team = game.away.team;
        sportLabel = "MLB";
    }else if(["nfl", "nba"].includes(sport)){
        let datetime_obj = formatDatetimeString(game.date);
        date = datetime_obj.date;
        time = datetime_obj.time;
        if(sport == "nfl"){
            followedSystems = nflFollowedSystems;
            home_team = nflTeams[game.home.team_id];
            away_team = nflTeams[game.away.team_id];
            sportLabel = "NFL";
        }else if(sport == "nba"){
            followedSystems = nbaFollowedSystems;
            home_team = nbaTeams[game.home.team_id];
            away_team = nbaTeams[game.away.team_id];
            sportLabel = "NBA";
        }

    };
    const game_matches = matches.filter(match => match.game_id === game_id);
    const priv_matches = privateSystems.filter(system =>
        game_matches.some(game_match => game_match.system_id === system._id)
    );
    const followed_matches = followedSystems.filter(system =>
        game_matches.some(game_match => game_match.system_id === system._id)
    );
    
    return(
        <div className='full-page'>
            <div className='page-section'>
                <div id='upcoming-game'>
                    <div id='game-header-div'>
                        <h1 id='game-label'> {away_team} @ {home_team}</h1>
                        {sport === 'mlb' && (
                            <a
                                className="pageLink"
                                id="analyzer-link"
                                onClick={() => navigate('/game-analyzer', { state: { game: game } })}
                            >
                                Game Analyzer
                            </a>
                        )}
                        <div id='info-label'>
                            <span id='info-date'>{date}<br/>{time}</span> 
                            <span id='info-sport'>{sportLabel}</span>
                        </div>
                    </div>
                    <div id='matching-systems'> 
                        <h3>Matching Systems</h3>
                        {priv_matches.length > 0 && (
                                <ListGroup>
                                {priv_matches.map(system=>{
                                    return(
                                        <SystemPrev system={system} key={system._id} sysType ="private"/>
                                    )
                                })}
                            </ListGroup>
                        )}
                        {followed_matches.length > 0 && (
                                <ListGroup>
                                {followed_matches.map(system=>{
                                    return(
                                        <SystemPrev system={system} key={system._id} sysType ="followed"/>
                                    )
                                })}
                            </ListGroup>
                        )}
                        {followed_matches.length === 0 && priv_matches.length === 0 &&  
                            (<p>No matching systems</p>)
                        }
                    </div>
                    <div id='data-tables'>
                        <div id='stat-avgs'>
                            <h3>Stat Averages</h3>
                            {sport === "mlb" && (
                                <MlbStatAvgs game={game} />
                            )}
                            {sport === "nfl" && (
                                <NflStatAvgs game={game} />
                            )}
                            {sport === "nba" && (
                                <NbaStatAvgs game={game} />
                            )}
                        </div>
                        <div id='betting-lines'>
                            <h3>Betting Lines</h3>
                            {sport === "mlb" && (
                                <MlbBettingData game={game} />
                            )}
                            {["nfl", "nba"].includes(sport) && (
                                <SportBettingData game={game} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthProtected(UpcomingGame);