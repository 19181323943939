import React, { useEffect, useState } from 'react';
import {Form} from "react-bootstrap";

const SportBettingData = (props) => {
    const [totals, setTotals] = useState([]);
    const [spread, setSpread] = useState([]);
    const [moneyline, setMoneyline] = useState([]);
    const [selected, setSelected] = useState("totals");


    // run when game prop is set 
    useEffect(()=>{
        if(typeof props.game === 'object'){
            // loop through each set of odds, filter out any empty arrays, return most recent bet for each book
            if('totals' in props.game){
                let tmpTotals = [];
                for(const sb of props.game.totals){
                    // only include books that have lines
                    if(sb.lines.length > 0){
                        const pts = sb.lines[0].pts;
                        const over = sb.lines[0].over;
                        const under = sb.lines[0].under;
                        tmpTotals.push({book:sb.book, over: over, under: under, pts: pts});
                    };
                };
                setTotals(tmpTotals);
            };

            if('spread' in props.game){
                let tmpSpread = [];
                for(const sb of props.game.spread){
                    // only include books that have lines
                    if(sb.lines.length > 0){
                        let away_pts_str, home_pts_str = '';
                        const away_pts = sb.lines[0].away.pts;
                        if(away_pts > 0){
                            away_pts_str = `+${away_pts}`
                        }else away_pts_str = String(away_pts);
                        const away = {pts: away_pts_str, odds: sb.lines[0].away.odds};
    
                        const home_pts = sb.lines[0].home.pts;
                        if(home_pts > 0){
                            home_pts_str = `+${home_pts}`
                        }else home_pts_str = String(home_pts);
                        const home = {pts: home_pts_str, odds: sb.lines[0].home.odds};
    
                        tmpSpread.push({book:sb.book, home: home, away: away});
                    };
                };
                setSpread(tmpSpread);
            };

            if('ml' in props.game){
                let tmpML = [];
                for(const sb of props.game.ml){
                    // only include books that have lines
                    if(sb.lines.length > 0){
                        const away = sb.lines[0].away;
                        const home = sb.lines[0].home;
                        tmpML.push({book:sb.book, home: home, away: away});
                    };
                };
                setMoneyline(tmpML);
            };
        };

    },[props.game]);


    return (
        <>
            <Form.Select id="bet-select" value={selected} onChange={e => setSelected(e.target.value)}>
                <option value="totals">Totals</option>
                <option value="spread">Spread</option>
                <option value="moneyline">Moneyline</option>
            </Form.Select>
            {selected === "totals" && (
                totals.length > 0 ? (
                    <table className='oddsTable'>
                        <thead>
                            <tr>
                                <th>Book</th>
                                <th>Line</th>
                                <th>Over</th>
                                <th>Under</th>
                            </tr>
                        </thead>
                        <tbody>
                        {totals.map(book => {
                            return(
                                <tr key={book.book}>
                                    <td>{book.book}</td>
                                    <td>{book.pts}</td>
                                    <td>{book.over}</td>
                                    <td>{book.under}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                ) : ( 
                    <h6>Odds unavailable</h6>
                )
            )}

            {selected === "moneyline" && (
                moneyline.length > 0 ? (
                    <table className='oddsTable'>
                        <thead>
                            <tr>
                                <th>Book</th>
                                <th>Home</th>
                                <th>Away</th>
                            </tr>
                        </thead>
                        <tbody>
                        {moneyline.map(book => {
                            return(
                                <tr>
                                    <td>{book.book}</td>
                                    <td>{book.home}</td>
                                    <td>{book.away}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                ) : ( 
                    <h6>Odds unavailable</h6>
                )
            )}

            {selected === "spread" && (
                spread.length > 0 ? (
                    <table className='oddsTable'>
                        <thead>
                            <tr>
                                <th>Book</th>
                                <th>Home</th>
                                <th>Away</th>
                            </tr>
                        </thead>
                        <tbody>
                        {spread.map(book => {
                            return(
                                <tr>
                                    <td>{book.book}</td>
                                    <td>{`${book.home.pts} (${book.home.odds})`}</td>
                                    <td>{`${book.away.pts} (${book.away.odds})`}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                ) : ( 
                    <h6>Odds unavailable</h6>
                )
            )}
        </>
    );
};

export default SportBettingData;