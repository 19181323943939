import React, {useState, useEffect, useContext} from 'react';
import { Form, FloatingLabel} from 'react-bootstrap';
import { SystemContext } from './SystemBuilder';

const SystemInfo = (props) => {
    const sysContext = useContext(SystemContext)
    const [errs, setErrs] = useState([]);
    const [bets, setBets] = useState({});

    // Run when sport changes
    useEffect(() => {
        if(sysContext.sport !== ""){
            const regBets = {
                "home.ml":"Home Moneyline",
                "away.ml":"Away Moneyline",
                "over": "Over",
                "under": "Under"
            };
            const mlbBets = {
                "home.runline": "Home Runline",
                "away.runline": "Away Runline"
            };
            const nflBets = {
                "home.spread": "Home Spread",
                "away.spread": "Away Spread"
            };
            const nbaBets = {
                "home.spread": "Home Spread",
                "away.spread": "Away Spread"
            };
            
            // set bets state variable 
            let tmpBets = {}
            if(sysContext.sport === "mlb"){
                tmpBets = {...regBets, ...mlbBets}; 
            }else if(sysContext.sport === "nfl"){
                tmpBets = {...regBets, ...nflBets}
            }else if(sysContext.sport === "nba"){
                tmpBets = {...regBets, ...nbaBets}
            };
            // sort keys alphabetically 
            const sortedKeys = Object.keys(tmpBets).sort();
            const sortedBets = {};
            sortedKeys.map(key=>{return(sortedBets[key] = tmpBets[key])});
            setBets(sortedBets);
        };
    },[sysContext]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);

    const validateForm = async (e) => {
        e.preventDefault();
        const tmpErrs = await props.validateSystemInfo();
        setErrs(tmpErrs);
        if(tmpErrs.length === 0){
            sysContext.setStep(2);
        };
    };

    return (
        <>
            <div id='header-row'>
                <h2>System Information</h2>
            </div>
            <Form id="systemForm">
                {props.pageType === "new" && (
                    <div className="switch-wrapper sysField">
                        <div className="switch-radio">
                            <input type="radio" id="private" name="systemType" checked={sysContext.sysType === "private"} onChange={()=>props.setSysType("private")}/>
                            <label for="private" >Private</label>
                        </div>
                        <div className="switch-radio">
                            <input type="radio" id="public" name="systemType" checked={sysContext.sysType === "public"} onChange={()=>props.setSysType("public")}/>
                            <label for="public">Public</label>
                        </div>
                    </div>
                )}
                <Form.Group>
                    <FloatingLabel label="Enter System Name" className="sysField" >
                        <Form.Control id="name" type="text" placeholder='Enter System Name' value={sysContext.name} onChange={(e)=>props.setName(e.target.value)}/>
                    </FloatingLabel>
                </Form.Group>
                <FloatingLabel label='Choose Sport' id="sport-div" className="sysField">
                    <Form.Control id="sport" as="select" value={sysContext.sport} onChange={(e)=>props.setSport(e.target.value)}>
                        <option value=""></option>
                        <option value="mlb" key="mlb" >MLB</option>
                        <option value="nfl" key="nfl" >NFL</option>
                        <option value="nba" key="nba" >NBA</option>
                    </Form.Control>
                </FloatingLabel>
                {sysContext.sport !== "" && (
                    <FloatingLabel label='Choose Bet' id="bet-div" className="sysField">
                        <Form.Control id="bet" as="select" value={sysContext.bet} onChange={(e)=>props.setBet(e.target.value)}>
                            <option value=""></option>
                            {Object.keys(bets).map(betKey=>(
                                <option value={betKey} key={betKey} >{bets[betKey]}</option>
                            ))}
                        </Form.Control>
                    </FloatingLabel>   
                )}
                <div id="submit-btns">
                    <button className='secondaryBtn'onClick={()=>props.navToSystems()}>Cancel</button>
                    <button className='primaryBtn'onClick={(e)=>validateForm(e)} >Next</button>
                </div>
            </Form>
        </>
    )
};

export default SystemInfo;