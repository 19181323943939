import React, {useEffect} from 'react';
// util imports 
import {fetch_user_systems, fetch_public_systems} from './utils/systems/systemUtils';
import apiService, {validateSession} from './utils/apiService';
import { fetchUpcomingGames } from './utils/apiRoutes';
import { useClearUserSession } from './hooks/user-hooks';
import { isToday } from './utils/general';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { setMlbUpcoming, setMlbPublicSystems } from './redux/mlbSlice';
import { setNflPublicSystems, setNflUpcoming } from './redux/nflSlice';
import { setNbaPublicSystems, setNbaUpcoming } from './redux/nbaSlice';

import { setPrivateSystems, setMatches, setFollowedSystems, setEmailSub } from './redux/userSlice';
import { setMissingOdds, setNflMissingOdds, setAppInfo } from './redux/applicationSlice';
import { useNavigate } from 'react-router-dom'; 
// Components
import { Outlet } from "react-router-dom";
import {Container} from 'react-bootstrap';
import { Chart, Filler } from 'chart.js';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/master.scss'

require('./App.css');

function App() {
  const navigate = useNavigate();
  const clearSession = useClearUserSession();
  Chart.register(Filler); // allows for the chart js graphs to have a filler
  const dispatch = useDispatch();
  const upcoming = useSelector((state) => state.mlb.upcoming);
  const permissions = useSelector((state) => state.user.permissions);
  const user_id = useSelector((state) => state.user.id);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const appInfo = useSelector(state=> state.app.info)

  // validate session on initial render
  useEffect(()=>{
    const validate = async() =>{
      // access token for session
      const token = localStorage.getItem('access_token');
      // only check for valid session in the case that a token exists in storage
      if(token){
        const isValid = await validateSession();
        if(isValid){
          setTimeout(3000);
        }else{
          clearSession();
          navigate('/signin');
        };
      };
    };
    validate();
  },[])

  // function to get system matches
  const fetch_sysMatches = async(ids) => {
    // body of post request
    const body = {
      ids: ids
    };
    try{
      // response of api request
      const payload = await apiService('systems/upcoming_matches', "POST", body);
      dispatch(setMatches(payload));
    }catch(error){
      if(error === 'Server Error' || error === 'Session Expired'){
          clearSession();
          navigate('/signin');
      };
    };
  };

  // get public mlb systems 
  const getPublicSystems = async(lastId=null) =>{
    const mlbResponse = await fetch_public_systems("mlb", lastId);
    const nflResponse = await fetch_public_systems("nfl", lastId);
    const nbaResponse = await fetch_public_systems("nba", lastId);

    dispatch(setMlbPublicSystems(mlbResponse));
    dispatch(setNflPublicSystems(nflResponse));
    dispatch(setNbaPublicSystems(nbaResponse));

  };

  // run when user changes, set state and fetch systems, system matches, and check for email subscription 
  useEffect(()=>{
    if(user_id!== undefined && user_id !== null){
      // function get systems, matches, and user permissions
      const await_fetch = async ()=>{
        // get systems
        let systems = await fetch_user_systems(user_id, clearSession, navigate);
        dispatch(setPrivateSystems(systems.privateSystems));
        dispatch(setFollowedSystems(systems.followedSystems));
        getPublicSystems();
        // get system matches
        // privateIds
        let privSysIds = systems.privateSystems.map(sys => {return sys._id});
        // publicIds 
        let pubIds = [];
        for(const key in systems.followedSystems){
          let ids = systems.followedSystems[key].map(sys => {return sys._id})
          pubIds = pubIds.concat(ids)
        };
        let sys_ids = privSysIds.concat(pubIds)
        fetch_sysMatches(sys_ids);
        // check if user is subbed to email notifications
        try{
          const emailSub = await apiService('app/email-sub-status');
          dispatch(setEmailSub(emailSub.email_sub));
        }catch(error){
          console.log(error);
        };
      };
      // call function to get data
      await_fetch(navigate);
    };
  },[user_id]);

  // fetch upcoming games if user is logged in
  useEffect(()=>{ 
    let checkGames = async() => {
      // make sure user is logged in
      if(loggedIn){
        // if last updated is not today (or upcoming is null - fetch upcoming
        if(!upcoming || !isToday(new Date(appInfo.upcoming_timestamp))){
          try{
              const mlbResponse = await fetchUpcomingGames('mlb/upcoming');
              const nflResponse = await fetchUpcomingGames('nfl/week/games?week=current');
              const nbaResponse = await fetchUpcomingGames('nba/upcoming');

              let tmpMlbUpcoming = mlbResponse;
              let tmpNflUpcoming = nflResponse;
              let tmpNbaUpcoming = nbaResponse;

              let tmpAppInfo = {...appInfo};
              tmpAppInfo.upcoming_timestamp = Date.now();
              dispatch(setMlbUpcoming(tmpMlbUpcoming));
              dispatch(setNflUpcoming(tmpNflUpcoming));
              dispatch(tmpNbaUpcoming(tmpNflUpcoming));

              dispatch(setAppInfo(tmpAppInfo));   
          }catch(error){
              if(error === 'Server Error' || error === 'Session Expired'){
                  clearSession();
                  navigate('/signin');
              };
          };
        };
      };
    };
    checkGames();
  },[loggedIn, upcoming]);

  return (
    <Container fluid id="mainContainer">
        <NavBar />
        <Outlet />
        <Footer />
    </Container>
  );
};

export default App;