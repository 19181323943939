// team id -> team name conversion
export const nflTeams = {
    "NYG":"Giants",
    "SF":"49ers",
    "TEN":"Titans",
    "CLE":"Browns",
    "ATL":"Falcons",
    "DET":"Lions",
    "NO":"Saints",
    "GB":"Packers",
    "HOU":"Texans",
    "JAX":"Jaguars",
    "DEN":"Broncos",
    "MIA":"Dolphins",
    "LAC":"Chargers",
    "MIN":"Vikings",
    "NE":"Patriots",
    "NYJ":"Jets",
    "IND":"Colts",
    "BAL":"Ravens",
    "BUF":"Bills",
    "WAS":"Commanders",
    "CAR":"Panthers",
    "SEA":"Seahawks",
    "DAL":"Cowboys",
    "ARI":"Cardinals",
    "CHI":"Bears",
    "KC":"Chiefs",
    "PIT":"Steelers",
    "LV":"Raiders",
    "PHI":"Eagles",
    "TB":"Buccaneers",
    "CIN":"Bengals",
    "LAR":"Rams",
};

export const nflConferences = {
    "AFC": {
        "East": ["BUF", "MIA", "NE", "NYJ"],
        "North": ["BAL", "CIN", "CLE", "PIT"],
        "South": ["HOU", "IND", "JAX", "TEN"],
        "West": ["DEN", "KC", "LV", "LAC"]
    },
    "NFC": {
        "East": ["DAL", "NYG", "PHI", "WAS"],
        "North": ["CHI", "DET", "GB", "MIN"],
        "South": ["ATL", "CAR", "NO", "TB"],
        "West": ["ARI", "LAR", "SF", "SEA"]
    }
};
export const nflStatVerbs = {
    "off.rushing" : {
        "off.rushing.att": "are",
        "off.rushing.yds": "are",
        "off.rushing.tds": "are",
        "off.rushing.long": "is",
        "off.rushing.first_downs": "are",
        "off.rushing.ybc": "are",
        "off.rushing.yac": "are",
        "off.rushing.broken_tackles": "are"
    },
    "off.passing":{ 
        "off.passing.att": "are",
        "off.passing.comp": "are",
        "off.passing.yds": "are",
        "off.passing.tds": "are",
        "off.passing.ints": "are",
        "off.passing.net_yds": "are",
        "off.passing.pass_rating": "is",
        "off.passing.long": "is",
        "off.passing.first_downs": "are",
        "off.passing.intended_air_yds": "are",
        "off.passing.comp_air_yds": "are",
        "off.passing.yac": "are",
        "off.passing.poor_throws": "are",
        "off.passing.blitzed": "are",
        "off.passing.hurried": "are",
        "off.passing.hits_taken": "are",
        "off.passing.pressured": "are",
        "off.passing.scrambles": "are",
        "off.passing.scramble_yds": "are",
        "off.passing.dropbacks": "are",
        "off.passing.spikes_throwaways": "are",
        "off.sacks.taken": "are",
        "off.sacks.yds_lost": "are"
    },
    "off.receiving": {
        "off.receiving.ybc": "are",
        "off.receiving.yac": "are",
        "off.receiving.broken_tackles": "are",
        "off.receiving.first_downs": "are",
        "off.receiving.drops": "are"
    },
    "off.general": {
        "off.first_downs": "are",
        "off.third_downs.att": "are", 
        "off.third_downs.conv": "are",
        "off.fourth_downs.att": "are", 
        "off.fourth_downs.conv": "are",
        "off.total_yds": "are",
        "off.fumbles": "are",
        "off.fumbles_lost": "are", 
        "off.turnovers": "are",
        "off.possession": "are"
    },
    "def.passing": {
        "def.passing.ints": "are",
        "def.passing.int_yds": "are",
        "def.passing.int_tds": "are",
        "def.passing.int_long": "is",
        "def.passing.passes_defended": "are"
    },
    "def.tackles": {
        "def.tackles.sacks": "are",
        "def.tackles.solo": "are",
        "def.tackles.assists": "are",
        "def.tackles.for_loss": "are",
        "def.tackles.qb_hits": "are",
        "def.missed_tackles": "are"
    },
    "def.fumbles": {
        "def.fumbles.recovered": "are",
        "def.fumbles.yds_ret": "are",
        "def.fumbles.tds": "are",
        "def.fumbles.forced": "are"
    },
    "def.general": {
        "def.player_blitzes": "are",
        "def.player_hurries": "are",
        "def.qb_knockdowns": "are",
        "def.player_pressures": "are"
    },
    "st.kicking": {
        "st.kicking.punt": "are", 
        "st.kicking.punt_yds": "are",
        "st.kicking.punt_long": "is",
        "st.kicking.xpm": "are",
        "st.kicking.xpa": "are",
        "st.kicking.fgm": "are",
        "st.kicking.fga": "are"
    }
};

export const nflStats = {
    "off.rushing" : {
        "off.rushing.att": "rushing attempts",
        "off.rushing.yds": "rushing yards",
        "off.rushing.tds": "rushing tds",
        "off.rushing.long": "longest rush",
        "off.rushing.first_downs": "first downs rushing",
        "off.rushing.ybc": "yards before contact",
        "off.rushing.yac": "yards after contact",
        "off.rushing.broken_tackles": "broken tackles"
    },
    "off.passing": {
        "off.passing.att": "passing attempts",
        "off.passing.comp": "passing completions",
        "off.passing.yds": "passing yards",
        "off.passing.tds": "passing touchdowns",
        "off.passing.ints": "interceptions thrown",
        "off.passing.net_yds": "net passing yards",
        "off.passing.pass_rating": "pass rating",
        "off.passing.long": "longest pass",
        "off.passing.first_downs": "first downs passing",
        "off.passing.intended_air_yds": "intended air yards",
        "off.passing.comp_air_yds": "completed air yards",
        "off.passing.yac": "yards after catch",
        "off.passing.poor_throws": "poor throws",
        "off.passing.blitzed": "qb times blitzed",
        "off.passing.hurried": "qb times hurried",
        "off.passing.hits_taken": "qb hits taken",
        "off.passing.pressured": "qb times pressured",
        "off.passing.scrambles": "scrambles",
        "off.passing.scramble_yds": "scramble yards",
        "off.passing.dropbacks": "dropbacks",
        "off.passing.spikes_throwaways": "spikes and throwaways",
        "off.sacks.taken": "sacks taken",
        "off.sacks.yds_lost": "yards lost from sacks",
    },
    "off.receiving": {
        "off.receiving.ybc": "receiving yards before contact",
        "off.receiving.yac": "receiving yards after contact",
        "off.receiving.broken_tackles": "broken tackles",
        "off.receiving.first_downs": "first downs receiving",
        "off.receiving.drops": "passes dropped"
    },
    "off.general": {
        "off.first_downs": "offensive first downs",
        "off.third_downs.att": "third down attempts", 
        "off.third_downs.conv": "third down conversions",
        "off.fourth_downs.att": "fourth down attempts", 
        "off.fourth_downs.conv": "fourth down conversions",
        "off.total_yds": "total offensive yards",
        "off.fumbles": "fumbles",
        "off.fumbles_lost": "fumbles lost", 
        "off.turnovers": "turnovers",
        "off.possession": "minutes of possession"
    },
    "def.passing": {
        "def.passing.ints": "defensive interceptions",
        "def.passing.int_yds": "interception yards returned",
        "def.passing.int_tds": "pick sixes",
        "def.passing.int_long": "longest interception return",
        "def.passing.passes_defended": "passes defended"
    },
    "def.tackles": {
        "def.tackles.sacks": "defensive sacks",
        "def.tackles.solo": "solo tackles",
        "def.tackles.assists": "assisted tackles",
        "def.tackles.for_loss": "tackles for a loss",
        "def.tackles.qb_hits": "defensive qb hits",
        "def.missed_tackles": "missed tackles"
    },
    "def.fumbles": {
        "def.fumbles.recovered": "fumbles recovered",
        "def.fumbles.yds_ret": "fumble yards returned",
        "def.fumbles.tds": "fumbles returned for a touchdown",
        "def.fumbles.forced": "forced fumbles"
    },
    "def.general": {
        "def.player_blitzes": "players sent on a blitz",
        "def.player_hurries": "total players who hurried the qb",
        "def.qb_knockdowns": "qb knockdowns",
        "def.player_pressures": "total players who pressured the qb",
    },
    "st.kicking": {
        "st.kicking.punt": "punts", 
        "st.kicking.punt_yds": "punt yards",
        "st.kicking.punt_long": "longest punt",
        "st.kicking.xpm": "extra points made",
        "st.kicking.xpa": "extra points attempted",
        "st.kicking.fgm": "field goals made",
        "st.kicking.fga": "field goals attempted"
    }
};