import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    upcoming: [],
    publicSystems: []
}

const nbaSlice = createSlice({
    name: 'nba',
    initialState,
    reducers:{
        setNbaUpcoming: (state, action) => {
            state.upcoming = action.payload
        },
        setNbaPublicSystems: (state, action) => {
            state.publicSystems = action.payload
        },
    }
})

export const {setNbaUpcoming, setNbaPublicSystems} = nbaSlice.actions;
export default nbaSlice.reducer;
